.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

img {
    height: 35%;
}

input:focus {
    outline: none;
}

.image-container {
    width:100%;
}

.search-input-container {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-left: 10px;
}


.search-input {
    border: none;
    box-shadow: 2px 2px 6px #ccc;
    width: 100%;
    height: 40%;
    margin-right: 10px;
    padding: 10px;
    background-color: honeydew;
}

.search-button {
    padding: '10px';
    margin: '10px';
    background-color: '#6bd569';
    color: '#000';
    font-weight: 'bolder';
    border-radius: '10px';
    border-color: '#6bd569';
}