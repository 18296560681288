.navbar {
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
    background-color: #6bd569;
    padding: 10px;
    color: black;
    text-align: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    position: fixed; /* set the position property to fixed */
    top: 0; /* position the navbar at the top of the page */
    z-index: 1; /* set the z-index to ensure the navbar sits on top of other elements */
}

.navbar-under {
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5);
    background-color: #fe6464;
    padding: 10px;
    color: black;
    text-align: center;
    font-size: small;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    position: fixed; /* set the position property to fixed */
    top: 45px; /* position the navbar at the top of the page */
    z-index: 1; /* set the z-index to ensure the navbar sits on top of other elements */
}

.navbar h1 {
    text-align: right;
    background-color: #6bd569;
}

.home-link {
    color: #333; /* set the color to dark gray */
    text-decoration: none; /* remove the underline */
}

.home-link-under {
    color: #ffffff; /* set the color to dark gray */
    text-decoration: none; /* remove the underline */
}